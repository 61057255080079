* {
  margin: 0;
}
.App {
  max-width: 1550px;
  display: block;
  margin: 0 auto;
}
.montserrat {
  font-family: 'Montserrat', sans-serif;
}
.alignment {
  text-align: left;
}

html,
body {
  margin: 0;
}

.input-field-wraper {
  @apply w-full bg-black text-white mb-2 px-2 py-1;
  border-radius: 4px;
}
.input-field-wraper > p {
  @apply mb-0;
}
.input-field-wraper > input,
.input-field-wraper > select,
.input-field-wraper > textarea {
  @apply w-full bg-black placeholder-white outline-0 placeholder:italic font-bold;
}
.element-dts {
  @apply flex;
}
.element-dts-type {
  @apply mr-2 sm:mr-4 font-bold montserrat;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  color: white;
}
@media (max-width: 768px) {
  .alignment {
    text-align: center;
  }
}
